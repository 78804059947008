import React from "react"

import { graphql } from "gatsby"
import { Layout, Seo, Nav } from "components/common"
import { useContentfulSection, useMeta } from "utils"

// import { CtaGetDemo } from "components/cta"
// import Footer from "./Footer"
import {
  HomeHero,
  HomeProblem,
  HomeSolution,
  HomeTechnology,
  HomeMesh,
  HomeNetwork,
  HomeScale,
  BottomCta,
} from "src/sections"

const IndexPage = ({ data: { home } }) => {
  const meta = useMeta(home)
  const {
    home_hero,
    home_problem,
    home_solution,
    home_technology,
    home_mesh,
    home_network,
    home_scale,
    bottom_cta,
  } = useContentfulSection(home.sections)

  return (
    <Layout>
      <Seo {...meta} />
      <Nav />

      <HomeHero {...home_hero} />
      <HomeProblem {...home_problem} />
      <HomeSolution {...home_solution} />
      <HomeTechnology {...home_technology} />
      <HomeMesh {...home_mesh} />
      <HomeNetwork {...home_network} />
      <HomeScale {...home_scale} />
      <BottomCta {...bottom_cta} />

      {/* <Footer /> */}
    </Layout>
  )
}

export const query = graphql`
  {
    home: contentfulPage(pageId: { eq: "home" }) {
      ...Page
    }
  }
`

export default IndexPage
