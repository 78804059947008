import React, { useCallback } from "react"
import styled from "styled-components"
import { HSection, Cta, TextGroup, Link } from "components/common"

import map from "lodash/map"
import { device } from "src/utils"

const HomeHero = ({ hat, title, subtitle, media, ctas, valueProps }) => {
  const renderValueProps = useCallback(
    () => (
      <StyledValueProps>
        {map(valueProps, (vp) => {
          const linkProps = vp.href
            ? { target: "_blank", rel: "noreferrer" }
            : {}

          return (
            <li key={vp.id}>
              <Link href={vp.href} to={vp.to} className="vp" {...linkProps}>
                <TextGroup subtitle={vp.title} />
              </Link>
            </li>
          )
        })}
      </StyledValueProps>
    ),
    [valueProps]
  )

  return (
    <>
      <StyledHomeHero>
        <HSection
          hat={hat}
          title={title}
          titleAs="h1"
          subtitle={subtitle}
          media={media}
          critical
          cta={() => <Cta ctas={ctas} />}
          mobileImageFull
        />
      </StyledHomeHero>
      <div style={{ overflow: "hidden", padding: "2px 0px" }}>
        {renderValueProps()}
      </div>
    </>
  )
}

const StyledHomeHero = styled.div`
  position: relative;
  --section-padding-bottom: 0;
  --section-padding-top: 0;
  --section-mobile-padding-top: 0;
  --section-mobile-padding-bottom: 0;

  .half-side-container {
    --tg-max-width: 465px;

    @media ${device.laptop} {
      --tg-max-width: 650px;
      min-height: 650px;
      --h-section-columns: 1fr;
    }
    .horizontal_textSection {
      order: 1;

      @media ${device.laptop} {
        margin-bottom: 125px;
      }
      .title {
        .bold {
          color: var(--lightBlue);
        }
      }

      .subtitle {
        max-width: 500px;
      }
    }

    .horizontalWrap_image {
      order: 2;

      position: relative;
      display: block;
      left: calc(var(--section-mobile-padding) * -1);
      margin-right: calc(var(--section-mobile-padding) * -2);

      margin-top: -80px;
      margin-bottom: -80px;

      @media ${device.tablet} {
        left: calc(var(--section-padding) * -1);
        margin-right: calc(var(--section-padding) * -2);
      }

      @media ${device.laptop} {
        position: absolute;
        left: initial;
        right: 0;
        bottom: 0;
        margin-top: 0;
        margin-bottom: 0;
        margin-right: 0;

        height: 76%;
      }

      @media screen and (min-width: 1280px) and (max-width: 1439px) {
        height: 91%;
      }

      @media ${device.desktop} {
        height: 100%;
      }

      .hSection-media {
        width: 100%;
        height: 100%;

        img {
          height: 100%;
          width: 100%;
          object-fit: cover !important;
        }
      }
    }
  }
`

const StyledValueProps = styled.ul`
  display: grid;
  grid-template-columns: 1fr;
  position: relative;
  z-index: 2;

  @media ${device.laptop} {
    grid-template-columns: repeat(6, 1fr);
  }

  .vp {
    display: block;

    background: var(--black3);
    padding: var(--sp-24);
    border-top: 0.5px solid #386b73;
    border-bottom: 0;
    transition: 0.1s all ease-in-out;
    transition-property: box-shadow, transform;

    @media ${device.laptop} {
      border: 0.5px solid #386b73;
      border-right: none;
      padding: var(--sp-32);

      &:hover {
        border: 0.5px solid #386b73;
        transform: scale(1.03);
        background: linear-gradient(145.76deg, #28464a 15.03%, #1d373b 83.96%);
        box-shadow: 0px 4px 36px #0c191b;
      }
    }

    .subtitle {
      text-align: center;
    }
  }

  li {
    &:first-child {
      @media ${device.laptop} {
        .vp {
          border-left: none !important;
        }
      }
    }
    &:last-child {
      .vp {
        border-bottom: 0.5px solid #386b73;
      }

      @media ${device.laptop} {
        .vp {
          border-right: none;
        }
      }
    }
  }
`

export default HomeHero
