import React, { useLayoutEffect } from "react"
import styled from "styled-components"
import { VerticalSection, Cta } from "components/common"

import Loop from "src/images/bottomCta/loopMesh.inline.svg"
import { useInView } from "src/utils"

const BottomCta = ({ hat, title, subtitle, ctas }) => {
  return (
    <StyledBottomCta>
      <VerticalSection
        hat={hat}
        title={title}
        subtitle={subtitle}
        custom={AnimatedLoop}
        customOrder="1"
        mobileCustomOrder="1"
        tgOrder="2"
        mobileTgOrder="2"
        align="center"
        cta={() => <Cta ctas={ctas} />}
      />
    </StyledBottomCta>
  )
}

const StyledBottomCta = styled.div`
  --tg-max-width: 620px;
`

const AnimatedLoop = () => {
  const { ref, isVisible } = useInView()

  function randomIntFromInterval(min, max) {
    // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min)
  }

  useLayoutEffect(() => {
    let animate = null

    const startAnimation = () => {
      animate = setInterval(() => {
        const current = document.querySelectorAll(".active")

        if (current) {
          current.forEach((c) => c?.classList.remove("active"))
        }

        const randomIdx = randomIntFromInterval(1, 17)
        const randomIdx2 = randomIntFromInterval(18, 35)
        const randomIdx3 = randomIntFromInterval(36, 53)
        const randomIdx4 = randomIntFromInterval(54, 70)

        const dot = document.getElementById(`dot${randomIdx}`)
        dot?.classList.add("active")

        const dot2 = document.getElementById(`dot${randomIdx2}`)
        dot2?.classList.add("active")

        const dot3 = document.getElementById(`dot${randomIdx3}`)
        dot3?.classList.add("active")

        const dot4 = document.getElementById(`dot${randomIdx4}`)
        dot4?.classList.add("active")
      }, 1000)
    }

    if (isVisible) {
      startAnimation()
    } else {
      clearInterval(animate)
    }

    return () => {
      clearInterval(animate)
    }
  }, [isVisible])

  // dot70
  return (
    <StyledLoop ref={ref}>
      <Loop />
    </StyledLoop>
  )
}

const StyledLoop = styled.div`
  margin: 0 auto;
  text-align: center;

  svg {
    width: 100%;
    height: auto;
    max-width: 690px;

    circle {
      transition: 1s all ease-in-out;
      transition-property: opacity;
    }

    .active {
      circle {
        opacity: 0;
      }
    }
  }
`

export default BottomCta
