import React from "react"
import styled from "styled-components"
import { HSection } from "components/common"

import { device } from "utils"

const HomeTechnology = ({ hat, title, subtitle, media }) => {
  return (
    <StyledHomeTechnology>
      <HSection hat={hat} title={title} subtitle={subtitle} media={media} />
    </StyledHomeTechnology>
  )
}

const StyledHomeTechnology = styled.div`
  --tg-max-width: 580px;

  --section-mobile-padding-top: 0;
  --section-padding-bottom: 0;

  .horizontal_textSection {
    @media ${device.laptop} {
      order: 1;
    }
  }
  .horizontalWrap_image {
    align-items: center;
    @media ${device.laptop} {
      align-items: flex-start;
      order: 2;
    }

    img {
      height: auto;
      width: 100%;
    }
  }
`

export default HomeTechnology
