import React from "react"
import styled from "styled-components"
import { VerticalSection } from "components/common"

const HomeProblem = ({ hat, title, subtitle, media }) => {
  return (
    <StyledHomeProblem>
      <VerticalSection
        hat={hat}
        title={title}
        subtitle={subtitle}
        media={media}
      />
    </StyledHomeProblem>
  )
}

const StyledHomeProblem = styled.div`
  --tg-max-width: 850px;
  --tg-hat-color: var(--problem);
`

export default HomeProblem
