import React from "react"
import styled from "styled-components"
import { HSection } from "components/common"

import { device } from "utils"

const HomeMesh = ({ hat, title, subtitle, media }) => {
  return (
    <StyledHomeMesh>
      <HSection hat={hat} title={title} subtitle={subtitle} media={media} />
    </StyledHomeMesh>
  )
}

const StyledHomeMesh = styled.div`
  --tg-max-width: 580px;
  --section-mobile-padding-top: 0;
  --section-padding-bottom: 0;

  .tgWrap {
    .subtitle {
      white-space: break-spaces;
    }
  }

  .horizontal_textSection {
    grid-row-start: auto;
    order: 2;
  }

  .horizontalWrap_image {
    grid-row-start: auto;

    align-items: center;
    @media ${device.laptop} {
      /* align-items: flex-end; */
    }

    img {
      height: auto;
      width: 100%;
    }
  }
`

export default HomeMesh
