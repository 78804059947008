import React from "react"
import styled from "styled-components"
import { VerticalSection, Grid, ValueProp } from "components/common"
import classNames from "classnames"

const HomeSolution = ({ hat, title, media, valueProps }) => {
  const renderGrid = () => (
    <Grid
      col={1}
      list={valueProps}
      align="center"
      width="1fr"
      gap={64}
      mobileGap={"32px"}
      component={(vp) => (
        <ValueProp
          {...vp}
          media={{ desktop: vp.icon }}
          titleAs="p"
          orientation="horizontal"
          className={classNames("solution-item", vp.vpId)}
        />
      )}
    />
  )

  return (
    <StyledHomeSolution>
      <VerticalSection
        hat={hat}
        title={title}
        media={media}
        grid={renderGrid}
        mobileFull
      />
    </StyledHomeSolution>
  )
}

const StyledHomeSolution = styled.div`
  --tg-max-width: 805px;
  --section-background-color: var(--black2);
  .imageWrap {
    margin-top: 0;
    img {
      width: 100%;
    }
  }

  .gridWrap {
    margin: 0 auto;

    .solution-item {
      &.home_solution_1 {
        --tg-hat-color: var(--cannabis);
      }

      &.home_solution_2 {
        --tg-hat-color: var(--forest);
      }
      &.home_solution_3 {
        --tg-hat-color: var(--moss);
      }

      &.home_solution_4 {
        --tg-hat-color: var(--seafoam);
      }
      &.home_solution_5 {
        --tg-hat-color: var(--shoreline);
      }
    }
  }
`

export default HomeSolution
