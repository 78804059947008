import React from "react"
import styled from "styled-components"
import { VerticalSection, Grid, ValueProp } from "components/common"
import classNames from "classnames"
import { device } from "src/utils"

const HomeScale = ({ hat, title, subtitle, media, valueProps }) => {
  const renderGrid = () => (
    <Grid
      list={valueProps}
      align="left"
      mobileGap="32px"
      col={4}
      component={(vp) => (
        <ValueProp
          {...vp}
          media={{ desktop: vp.icon }}
          titleAs="p"
          className={classNames("scale-item", vp.vpId)}
        />
      )}
    />
  )
  return (
    <StyledHomeScale>
      <VerticalSection
        hat={hat}
        title={title}
        subtitle={subtitle}
        media={media}
        align="left"
        grid={renderGrid}
      />
    </StyledHomeScale>
  )
}

const StyledHomeScale = styled.div`
  --tg-max-width: 700px;
  --section-background-color: var(--black2);

  .scale-item {
    &.home_scale_1 {
      --tg-hat-color: var(--cannabis);
    }

    &.home_scale_2 {
      --tg-hat-color: var(--forest);
    }
    &.home_scale_3 {
      --tg-hat-color: var(--moss);
    }

    &.home_scale_4 {
      --tg-hat-color: var(--seafoam);
    }
    &.home_scale_5 {
      --tg-hat-color: var(--shoreline);
    }

    .vp_contentWrap {
      grid-template-columns: auto 1fr;

      @media ${device.laptop} {
        grid-template-columns: 1fr;
      }
    }
  }
`

export default HomeScale
